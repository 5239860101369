import React, {Component} from "react";
import {STATIC_SERVER_URL} from "../../../config/config";

export default class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                    <section>
                        <p className="text-center">Copyright &copy; <a target="_blank" href="https://www.thewebillustrated.com/" title="The Web Illustrated">The Web Illustrated</a> 2021, Honvéd FC Kft.&nbsp;|&nbsp;
                            <a target="_blank" href="https://honvedfc.hu/tartalom/impresszum">Impresszum</a>&nbsp;|&nbsp;
                            <a target="_blank" href="https://honvedfc.hu/tartalom/suti-szabalyzat">Cookie
                                szabályzat</a>&nbsp;|&nbsp;
                            <a target="_blank" href="https://honvedfc.hu/tartalom/adatkezelesi-szabalyzat">Adatkezelési
                                szabályzat</a>&nbsp;|&nbsp;
                            <a target="_blank" href="https://honvedfc.hu/tartalom/beszamolok">TAO és éves beszámolók</a>
                        </p>
                    </section>
                </footer>
            </div>
        )
    }
}