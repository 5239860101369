console.log(process.env.REACT_APP_ENV);
const ENVIRONMENT = (process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : 'develop';

export const BASE_HREF = window.document.location.protocol + '//' + window.document.location.host;

export const HOST_NAME = window.document.location.host;

export const API_BASE_URL = (ENVIRONMENT === 'production')
    ? 'https://prod-api.honvedfc.hu/api'
    : (ENVIRONMENT === 'staging')
        ? 'https://dev-api.honvedfc.hu/api'
        : 'https://dev.honvedfc.local/api';

export const STATIC_SERVER_URL = (ENVIRONMENT === 'production')
    ? 'https://depo.honvedfc.hu'
    : (ENVIRONMENT === 'staging')
        ? 'https://depo.honvedfc.hu'
        : 'http://depo.honvedfc.local';

export const AUTH_BASE_URL = (ENVIRONMENT === 'production')
    ? 'https://auth-prod.honvedfc.hu/api'
    : (ENVIRONMENT === 'staging')
        ? 'https://auth-test.honvedfc.hu/api'
        : 'https://auth.honvedfc.local/api';
