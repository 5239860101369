import { STATIC_SERVER_URL } from "../../../config/config";
import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default class FaqContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqItems: [
        {
          id: 1,
          name: "létesítmény",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Mikor van nyitva a Bozsik Aréna?",
              answer:
                'A Bozsik Aréna futball mérkőzések és egyéb rendezvények idejére fogad vendégeket. Ezeken felül a stadiontúrák során van lehetőség a belépésre és olyan részek bejárására is, amik mérkőzések látogatásakor a nagyközönség számára nincsenek megnyitva. Jelentkezni a <a href="#contact">Kapcsolat</a> oldalon tudsz, szeretettel várunk!',
            },
            {
              id: 2,
              active: false,
              question: "Körbe nézhetek meccs előtt a Bozsik Arénában?",
              answer:
                "Természetesen! A kapunyitás általában 2 órával a mérkőzés kezdete előtt történik (ha ettől eltérő időpontban nyitunk ki, arról a honlapon adunk tájékoztatást). A belépést követően a jegyen látható szektort és annak környékét (A, B, C vagy D lelátórészt) és a lelátó alatti részt szabadon bejárhatod.",
            },
            {
              id: 3,
              active: false,
              question: "Van lehetőség stadiontúrára?",
              answer:
                'Igen, keresd az aktuális időpontokat és a jelentkezési lehetőséget a <a href="#contact">Kapcsolat</a> oldalon!',
            },
            {
              id: 4,
              active: false,
              question:
                "Van átjárási lehetőség a szektorok között, vagy le vannak zárva?",
              answer:
                "A stadion egyes oldalain (A, B, C, D hazai és külön a D vendég) lévő szektorok átjárhatóak.",
            },
          ],
        },
        {
          id: 2,
          name: "büfé",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Hogyan működik a repohár rendszer?",
              answer:
                "A Bozsik Arénában repohár rendszer működik. A büfékben minden választott ital mellé egységesen 350 Ft-ért egy repohárban kapod az italod. A következő vásárlásnál, ha vissza viszed a használt poharat, egy új tiszta repohárban kapod az italod vagy, amennyiben ezt jelzed a büféseknek, vissza is válthatod a használt repoharad, amiért cserébe egy tokent kapsz. Ezzel a tokennel a következő meccsen már térítésmentesen, egy tiszta repohárban kapod az italod.",
            },
            {
              id: 2,
              active: false,
              question:
                "Milyen ételeket és italokat lehet vásárolni a büfékben?",
              answer:
                "Ételek tekintetében a snackek (Popcorn, Chips, mogyoró stb.) mellett hot-dog, rántott csirkemelles szendvics és bécsi perec kapható. Az italok terén a szénsavas és szénsavmentes üditők és ásványvízek mellett természetesen a sörök viszik a prímet: Borsodi Mestert csapolunk és Horizont Easy A-t adunk dobozos formában, az alkoholmentes Stella mellett.",
            },
            {
              id: 3,
              active: false,
              question: "Vihetek be magammal ételt a Bozsik Aréna területére?",
              answer:
                "A Bozsik Arénába nem vihető be étel, kivételek ez alól a 6 év alatti látogatók.",
            },
            {
              id: 4,
              active: false,
              question: "Vihetek be magammal italt a Bozsik Aréna területére?",
              answer:
                "A Bozsik Arénába nem vihető be ital, kivételek ez alól a 6 év alatti látogatók.",
            },
            {
              id: 5,
              active: false,
              question:
                "Vihetek be magammal kulacsot a Bozsik Aréna területére?",
              answer:
                "A Bozsik Arénába nem vihető be kulacs, kivételek ez alól a 6 év alatti látogatók.",
            },
            {
              id: 6,
              active: false,
              question:
                "Milyen fizetési lehetőségek vannak a Bozsik Aréna területén?",
              answer:
                "A STADIONBAN BANKKÁRTYÁVAL ILLETVE EGY ERRE RENDSZERESÍTETT „HELLOPAY” FELTÖLTŐ KÁRTYÁVAL LEHET FIZETNI, AMIT A PÉNZTÁRBAN TUDSZ KIVÁLTANI ÉS FELTÖLTENI. KÉSZPÉNZES FIZETÉSRE NINCS LEHETŐSÉG, CSAK A JEGYPÉNZTÁRBAN LEHET KÉSZPÉNZZEL FIZETNI. A BOZSIK ARÉNA TERÜLETÉN LEHETŐSÉG VAN AZ ÉRINTŐKÉPERNYŐS TORNYOKNÁL „HELLOPAY” KÁRTYÁT IGÉNYELNI ÉS MEGLÉVŐ KÁRTYÁT FELTÖLTENI AKÁR BANKKÁRTYÁVAL AKÁR KÉSZPÉNZZEL.",
            },
          ],
        },
        {
          id: 3,
          name: "belépés",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question:
                "Mekkora táskát lehet bevinni a Bozsik Aréna területére?",
              answer: "20 cm x 20 cm x 30 cm",
            },
            {
              id: 2,
              active: false,
              question:
                "A laptop/laptop táskámat be tudom vinni a Bozsik Aréna területére?",
              answer: "Igen, a 20 x 20 x 30 cm-es méretkorláton belül.",
            },
            {
              id: 3,
              active: false,
              question:
                "Milyen laptop kiegészítőket/tartozékokat tudok bevinni a Bozsik Aréna területére?",
              answer: "A gép töltőjét és egerét.",
            },
            {
              id: 4,
              active: false,
              question: "Vannak olyan tárgyak, amik nem engedélyezettek?",
              answer:
                "Igen. Ezeket a pályarendszabály nevesíti, amit a belépésnél, a kapuk mellett találsz meg.",
            },
            {
              id: 5,
              active: false,
              question:
                "Megafont, drapériát, egyéb szurkolói kellékeket be lehet vinni a Bozsik Aréna területére?",
              answer:
                "A megafonnal történő belépés tilos! Szurkolói kellékek közül a csapatzászló behozatala lehetséges. Minden egyéb kellék kizárólag a klub előzetes engedélye mellett hozható be.",
            },
            {
              id: 6,
              active: false,
              question:
                "Ha közvetlenül a repülőtérről érkezem a Bozsik Arénába, van lehetőség a csomagmegőrzésre a mérkőzés ideje alatt?",
              answer:
                "Kizárólag 20 cm x 20 cm x 30 cm méretű táska hozható be. A beléptetési pontnál, a biztonsági szolgálatnál kell leadni minden ennél nagyobb méretű csomagot megőrzésre.",
            },
          ],
        },
        {
          id: 4,
          name: "parkolás",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Hol lehet parkolni?",
              answer:
                'A Bozsik Aréna parkolóinak használatával kapcsolatban kérjük jelezd igényed a <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> címen.',
            },
            {
              id: 2,
              active: false,
              question:
                "Hol javasolt parkolnom az autómmal a Bozsik Aréna környékén?",
              answer:
                "Puskás Ferenc utca, Hengersor utca, Hofherr Albert utca. Ezen kívül a Vaslemez és Darányi utcák által határolt területen ill. az Ady Endre útra merőleges alacsonyabb forgalmú utcákban a Vas Gereben utca irányában.",
            },
            {
              id: 3,
              active: false,
              question:
                "Van bicikli vagy motor tárolására alkalmas hely a Bozsik Aréna körül?",
              answer:
                "Igen. Minden kapucsoportnál találhatóak biciklitárolók. Nem őrzött tárolók, kérjük a kerékpárod lezárásáról gondoskodj. Motorral a B és C szektorok sarkánál, a Puskás utcán lévő trafóház mellett tudsz parkolni.",
            },
          ],
        },
        {
          id: 5,
          name: "megközelítés",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "autóval",
              answer:
                "<strong>M5 Szeged felől:</strong><br>A soroksári bevásárlóközpontok elhagyása után hajts ki a Hofherr Albert u. felé - A körforgalomnál haladj tovább erre: Hofherr Albert u. - Fordulj jobbra, a következő útra: Puskás Ferenc u.<br><br><strong>M5 Budapest felől:</strong><br>A Könyves Kálmán Körútról fordulj rá a következő útra: Gyáli úti felüljáró (M5/Szeged irányába mutató jelek) - Folytasd az utat a Gyáli út fele - Folytasd az utat a Nagykőrösi út fele - Térj le az OMW kút utáni kihajtón - Folytasd az utat a Nagykőrösi úton – A felüljáró alatt fordulj balra a Nagykörösi úton a város felé - A körforgalmat a(z) 1. kijáraton hagyd el ebbe az irányba: Hofherr Albert u. - Fordulj jobbra a következő útra: Puskás Ferenc u.<br><br><strong>A Deák Ferenc térről:</strong><br>A Károly körúton haladva a Múzeum körút felé - A Múzeum körútról fordulj balra az Üllői útra -  Az Üllői útról térj le jobbra a Kőér utcára - Tarts egyenesen a Ferde utcára - Folytasd az utat az Ady Endre úton majd megérkezel a Puskás Ferenc utcába.  <br><br><strong>KÖKI irányából:</strong><br>Haladj kelet felé a Vak Bottyán úton a Katica utca irányába, Fordulj jobbra a következő útra: Szabó Ervin utca - Fordulj balra a következő útra: Ady Endre út. Folytasd az utat az Ady Endre úton, majd megérkezel a Puskás Ferenc utcába. <br><br><strong>Pestszentlőrinc irányából:</strong><br>Haladj a Gilice Tértől - Margó Tivadar utca felé majd a Kinizsi Pál utcán. Haladj délkelet felé a Péterhalmi út irányába - Hagyd el a körforgalmat a Ciffra György utca irányába - Hagyd el a körforgalmat az első kijáraton, a Kele út irányába - Fordulj balra a következő útra: Kinizsi Pál utca. A Kinizsi Pál/Csapó utcán haladj tovább, majd megérkezel a Puskás Ferenc utcába.",
            },
            {
              id: 2,
              active: false,
              question: "tömegközlekedéssel",
              answer:
                "<strong>NYUGATI PÁLYAUDVAR:</strong><br>  \n" +
                "- Minden vonat, amely Kispest vasútállomáson megáll (pl. Lajosmizse végállomással) 20 perc alatt elvisz a Bozsik Arénától 10 perc sétára lévő kispesti állomásra.<br>\n" +
                "- Érkezés után a Puskás Ferenc utca felé kell elindulni. Ha nem tudsz vagy akarsz gyalogolni, szálj fel a Hengersor utcában a 93A jelű autóbuszra és a Bozsik Stadion megállónál szállj le.<br>\n" +
                "<strong>KŐBÁNYA-KISPEST (KÖKI):</strong><br>  \n" +
                "- 93A busz – A Bozsik Stadion megállóban szállj le.<br>\n" +
                "- 184 busz - Szállj le a Tinódi utca megállóban, majd sétálj 10 percet a Bozsik Aréna irányába.<br>\n" +
                "- 68 busz - Menj busszal a Templom tér megállóig, majd szállj át 42-es villamosra és szállj le a Tulipán utcánál, majd sétálj a Bozsik Aréna irányába.<br><br>\n" +
                "<strong>DEÁK FERENC TÉR:</strong><br>\n" +
                "- Szállj fel a 2-es metróra, majd a Blaha Lujza téren szállj át a 99-es buszra, a Határ úton szállj át a 42-es villamosra, utazz a Tulipán utcáig, majd sétálj a Bozsik Aréna irányába.<br>\n" +
                "- Szállj fel a 2-es metróra, majd az Örs Vezér terén szállj át a 85-ös buszra és utazz a Kőbánya-Kispest M megállóig, majd szállj át a 93-as buszra. majd szállj le a Bozsik Stadion megállónál.<br><br>\n" +
                "<strong>NÉPLIGET:</strong><br>\n" +
                "- Szállj fel a 3-as metróra, utazz a Kőbánya Kispest megállóig, majd szállj át a 93A buszra, és szállj le a Bozsik Stadion megállójánál.<br>\n" +
                "- Szállj fel a 3-as metróra, utazz a Határ út megállóig, majd szállj át a 42-es villamosra és a Tulipán utcai végállomáson szállj le, majd sétálj a Bozsik Aréna irányába.<br><br>\n" +
                "<strong>ÖRS VEZÉR TERE:</strong><br>\n" +
                "- Szállj fel a 85-ös buszra, utazz a Kőbánya-Kispest M megállóig, majd szállj át a 93-as buszra és utazz a Bozsik Stadion megállóig.<br>\n" +
                "- Szállj fel a 85-ös buszra a Gőzmozdony utcáig, majd a 68-as busszal menj el a Templom tér megállóig, itt szállj fel a 42-es villamosra a Tulipán utcáig, majd tarts a Bozsik Aréna irányába.<br><br>\n" +
                "<strong>HAVANNA LAKÓTELEP:</strong><br>\n" +
                "Az Iker utcai megállóban szállj fel a 93A buszra, majd a Bozsik Stadion megállóban szállj le. <br><br>\n" +
                "<strong>HATÁR ÚT:</strong><br>\n" +
                "A 42-es villamos a Határ úttól a Tulipán utcáig közlekedik. A kispesti végállomásról 3 perc gyalogosan a Bozsik Aréna.<br><br>\n" +
                "<strong>PESTSZENTLŐRINC:</strong><br>\n" +
                "- Szállj fel a Szarvas Csárda téren a 93A jelzésű buszra, majd a Bozsik Stadion megállónál szállj le.<br><br>\n" +
                "<strong>PESTERZSÉBET:</strong><br>\n" +
                "- Pesterzsébet Városközpontban szállj fel a 66-os buszra, majd a Határ útnál szállj át az 50-es villamosra. A Villanytelep utcánál szállj át a 93-as buszra és utazz a Bozsik Stadion megállóig.<br><br>\n" +
                "<strong>KELETI PU.:</strong><br>\n" +
                "- Szállj fel a 2M, 24 villamosra majd a Golgota téren szállj át a 99-es buszra. Utazz a Határ úti megállóig, majd szállj át a 42-es villamosra és a Tulipán utcai végállomáson szállj le, majd sétálj a Bozsik Aréna irányába.<br><br>\n" +
                "<strong>LISZT FERENC NEMZETKÖZI REPÜLŐTÉR:</strong><br>\n" +
                "- Szállj fel a 200E jelzésű buszra, majd a Szemeretelep vasútállomás megállónál szállj át a 93A buszra, ami a Bozsik Stadion megállóig viszi.<br><br>\n" +
                "<strong>TAXI:</strong><br>\n" +
                'A taxival érkezőknek és távozóknak a régi stadionbejáratnál, a vasúti átjáró közelében illetve a Temető köz elején a <a href="https://www.facebook.com/liptakgrundpub/" target="_blank">Lipták Grund Pub</a> mögött van lehetőségük ki- és beszállni a járművekből.',
            },
          ],
        },
        {
          id: 6,
          name: "aktivitások",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Mikor érdemes megérkezni a Bozsik Arénához?",
              answer:
                "Kérjük, érkezésed időzítésekor vedd figyelembe, hogy a jegy érvényesítése és a beléptetés időt vesz igénybe (kb. 15 perc), ha pedig a helyszínen kívánod megvásárolni jegyedet, úgy az is időigényes. Javasoljuk, hogy kb. 1 órával a mérkőzés megkezdése előtt érkezz meg.",
            },
            {
              id: 2,
              active: false,
              question:
                "Ha korán megérkezem a Bozsik Arénához, mit tudok ott csinálni?",
              answer:
                'A <a href="https://www.facebook.com/liptakgrundpub/" target="_blank">Lipták Grund Pub</a> minden nap 10 órától várja a szurkolókat, a  Bozsik Aréna <a href="https://shop.honvedfc.hu/" target="_blank">szurkolói shopja</a> pedig a mérkőzés kezdete előtt hat órával nyit. A stadion kapui két órával a mérkőzés kezdete előtt nyitnak, a kezdőrúgás előtt 45 perccel érdemes megtekinteni a csapatok bemelegítését!',
            },
            {
              id: 3,
              active: false,
              question: "Lehet dohányozni a Bozsik Arénában? Ha igen, hol?",
              answer:
                "A lelátón dohányozni ill. elektromos cigarettát szívni szigorúan tilos! A dohányzásra kijelölt helyeket a stadionon belül keresd a bejáratok mellett!",
            },
            {
              id: 4,
              active: false,
              question: "Mit lehet csinálni a mérkőzés után?",
              answer:
                "A büfék még 15 percig nyitva vannak a lefújás után, vissza tudod vinni a repoharakat vagy tudsz még vásárolni. Ha megvitatnátok a mérkőzésen történteket, térjetek be egy sörre, pizzára vagy kolbászra a Lipták Grund Pubba!",
            },
            {
              id: 5,
              active: false,
              question: "Van ajándékbolt? Ha igen, mit lehet ott vásárolni?",
              answer:
                'Természetesen van! A <a href="https://shop.honvedfc.hu/" target="_blank">Honvéd Shop</a> az C-kapu mellett helyezkedik el és a termékeket megtalálod a <a href="https://shop.honvedfc.hu" target="_blank">shop.honvedfc.hu</a> címen. Az üzlet nyitvatartása az alábbiak szerint alakul:<br><br>\n' +
                "Honvéd Shop, nyitvatartás:<br>\n" +
                "Hétfő: 14:00-19:00<br>\n" +
                "Kedd: Zárva<br>\n" +
                "Szerda: 14:00-19:00<br>\n" +
                "Csütörtök: Zárva<br>\n" +
                "Péntek: 14:00-19:00<br>\n" +
                "Mérkőzésnapokon 5 órával a mérkőzés kezdete előtt.\n",
            },
            {
              id: 6,
              active: false,
              question:
                "Hol tudok javaslatot vagy panaszt tenni a stadionnal kapcsolatban?",
              answer:
                'Javaslatait és panaszait a helyszínen jelezheti az „All-Staff” feliratú nyakban logó belépővel rendelkező kollégáinknak. Írásban az <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> e-mail címen, vagy a Budapest Honvéd FC 1194 Budapest, Temesvár u. 25/A. sz. alatti székhelyére címezve jelezheti észrevételeit.',
            },
          ],
        },
        {
          id: 7,
          name: "MOZGÁSKORLÁTOZOTTAK ÉS SPECIÁLIS IGÉNYŰ SZEMÉLYEK",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question:
                "Van mozgáskorlátozottak számára fenntartott parkolóhely?",
              answer:
                'Igen, kérjük, parkolási igényed jelezd a mérkőzés előtt a <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> címen és felvesszük veled a kapcsolatot.',
            },
            {
              id: 2,
              active: false,
              question:
                "Ha tömegközlekedéssel érkezem, könnyen meg tudom közelíteni a Bozsik Arénát?",
              answer:
                "Igen, részletes javaslatainkat lásd a „Tömegközlekedés” pont alatt.",
            },
            {
              id: 3,
              active: false,
              question:
                "Hol vannak a kerekesszékesek számára fenntartott helyek?",
              answer:
                "A Bozsik Aréna C2 és C9-es szektorában ill. a VIP szektorokban.",
            },
            {
              id: 4,
              active: false,
              question:
                "Milyen útvonalon tudom megközelíteni a mozgáskorlátozott helyeket?",
              answer:
                "Minden mozgáskorlátozott helyünk megközelítési útvonala akadálymentesített, de bátran kérd a helyszínen szervezőink vagy a biztonsági szolgálat segítségét.",
            },
            {
              id: 5,
              active: false,
              question:
                "Be tudom vinni a gyógyszereimet a Bozsik Aréna területére? Mi van abban az esetben, ha a gyógyszer folyadék?",
              answer:
                "Igen, de kérjük, hogy folyékony gyógyszert csak a legszükségesebb esetben hozz magaddal!",
            },
            {
              id: 6,
              active: false,
              question:
                "Van az Aréna területén mozgássérültek számára fenntartott mellékhelység?",
              answer: "Minden szektorban van mozgáskorlátozott mellékhelyiség.",
            },
          ],
        },
        {
          id: 8,
          name: "veszélyhelyzet",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Van mentőszolgálat a Bozsik Aréna területén?",
              answer:
                "Minden mérkőzésünkön rendelkezésre áll mentőszolgálat. A mérkőzésen fellépő, sürgős ellátást igénylő egészségügyi problémával kérjük, hogy a szervezőket, vagy a biztonsági szolgálatot szíveskedj megkeresni, és elkísérünk a mentőszolgálat munkatársaihoz.",
            },
            {
              id: 2,
              active: false,
              question: "Mi történik, ha megsérülök?",
              answer:
                "Ez esetben minél hamarabb fordulj a szervezőinkhez, vagy a biztonsági szolgálathoz!",
            },
          ],
        },
        {
          id: 9,
          name: "talált tárgyak",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question:
                "Elvesztettem a pénztárcámat, táskámat stb. Hol adhatták le?",
              answer:
                'A szektor biztonsági szolgálatát kérdezd meg először. Ha ez nem vezet eredményre, kérjük, írj az <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> e-mail címre vagy a mérkőzést követő munkanapon hívd az alábbi számot: +36 70 429 9935!',
            },
            {
              id: 2,
              active: false,
              question: "Mi történik, ha eltűnik a gyermekem?",
              answer:
                "Minden esetben forduljon a helyszíni szervezőinkhez, vagy a biztonsági szolgálathoz!",
            },
            {
              id: 3,
              active: false,
              question:
                "Mi történik, ha találtam egy pénztárcát, táskát stb? Hova tudom leadni?",
              answer:
                "Minden esetben forduljon a helyszíni szervezőinkhez, vagy a biztonsági szolgálathoz.",
            },
          ],
        },
        {
          id: 10,
          name: "családok",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question:
                "Van a Bozsik Arénában családi szektor? Ha igen, milyen kedvezményekkel jár?",
              answer:
                'A C3-C4-es szektorok az un. családi szektorok, ide 18 korig egy gyermek ingyenesen, a második és harmadik pedig kedvezményes áron jöhet be egy felnőtt kisérővel. A mérkőzéseink látogatása 6 éves kor alatt minden szektorba ingyenes, de regisztrációs jegy váltása szükséges. <a href="https://honvedfc.hu/tartalom/belepok" target="_blank">Bővebb információk.</a>',
            },
            {
              id: 2,
              active: false,
              question:
                "Hány éves kortól lehet megtekinteni a mérkőzéseket egyedül, szülői felügyelet nélkül?",
              answer: "14",
            },
            {
              id: 3,
              active: false,
              question:
                "Be lehet vinni a babakocsit a Bozsik Aréna területére?",
              answer: "Igen.",
            },
            {
              id: 4,
              active: false,
              question:
                "Be lehet vinni bébiételt vagy italt a gyermekek részére?",
              answer:
                "Igen, 6 éves korig a gyerekek részére be lehet hozni italt, a kicsiknek pedig bébiételt.",
            },
          ],
        },
        {
          id: 11,
          name: "honvéd meccsek",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question: "Hogy tudom megváltani a belépőjegyemet a mérkőzésre?",
              answer:
                'A belépőket online a <a href="https://jegy.mlsz.hu" target="_blank">https://jegy.mlsz.hu</a> oldalon, vagy a Bozsik Aréna pénztáraiban az alábbiakban látható időpontokban és helyszíneken lehet megvásárolni. Továbbá mérkőzésnapon, 3 órával a kezdő sípszó előtt, a stadion pénztáraiban.<br><br>\n' +
                "<strong>Jegypénzár, nyitva tartás:</strong><br>\n" +
                "Kedd: 15-20<br>\n" +
                "Péntek: 15-20<br>\n" +
                "Mérkőzésnapokon 3 órával a mérkőzés kezdete előtt.\n",
            },
            {
              id: 2,
              active: false,
              question:
                "Hogy találom meg a jegyemen szereplő kaput és a bejáratot?",
              answer:
                "A jegyen szerepel a stadion térképe a szektorok megjelölésével. Amennyiben mégis nehézségbe ütközik a helye elfoglalása, kérem, hogy forduljon a biztonsági szolgálathoz, vagy a szervezőkhöz.",
            },
            {
              id: 3,
              active: false,
              question: "Lehet találkozni a játékosokkal?",
              answer:
                "A mérkőzésen játszó játékosokkal az aktuális szabályozások miatt nem. A VIP területen elképzelhető, hogy az adott mérkőzésről kimaradó játékossal tudsz találkozni.",
            },
            {
              id: 4,
              active: false,
              question: "Van lehetőség autogramot kérni a játékosoktól?",
              answer:
                "A mérkőzésen játszó játékosokkal az aktuális szabályozások miatt nem.",
            },
            {
              id: 5,
              active: false,
              question: "Klubkártya kell a belépéshez?",
              answer:
                "Nem kötelező, csak a kiemelt mérkőzések jegyelővételekor szoktuk alkalmazni.",
            },
            {
              id: 6,
              active: false,
              question: "Mikor van nyitva az ajándékbolt?",
              answer:
                "Mérkőzésnapokon 6 órával a mérkőzés kezdete előtt nyit.<br><br>\n" +
                "<strong>Honvéd Shop, nyitva tartás</strong> (nem mérkőzésnapokon):<br>\n" +
                "Hétfő: 9-14<br>\n" +
                "Kedd: 14-19<br>\n" +
                "Szerda: Zárva<br>\n" +
                "Csütörtök: 9-14<br>\n" +
                "Péntek: 14-19\n",
            },
            {
              id: 7,
              active: false,
              question: "Milyen fizetési lehetőségek vannak az ajándékboltban?",
              answer: "Készpénz mellett kártyával is tudsz fizetni.",
            },
            {
              id: 8,
              active: false,
              question:
                "Mit lehet csinálni a stadionban illetve a környezetében található nagyméretű, képernyős eszközökkel, mire tudom használni őket?",
              answer:
                'Három típust különböztetünk meg az érintőképernyős, interaktív eszközökből: a jegynyomtatásra alkalmasat, a stadionon belüli kártyás fizetést megkönnyítő, kártyafeltöltő funkcióval rendelkezőt és az alap, információ közlőt. Mindegyiknek az oldalán egy felirat segít beazonosítani. Ha valamelyik eszközzel kapcsolatban észrevételed van, kérjük írj a <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> címre.',
            },
          ],
        },
        {
          id: 12,
          name: "rendezvények",
          active: false,
          items: [
            {
              id: 1,
              active: false,
              question:
                "Hol tudok árajánlatot kérni, ha szeretnék rendezvény csinálni, szervezni a Bozsik Arénában?",
              answer:
                'Kérjük, írj a <a href="mailto:bozsikarena@bhfc.hu">bozsikarena@bhfc.hu</a> címre és néhány információt adjál nekünk az eseménnyel, ötlettel kapcsolatban!',
            },
            {
              id: 2,
              active: false,
              question:
                "Milyen rendezvényeket lehet tartani a Bozsik Arénában?",
              answer:
                "Sajtótájékoztatók, workshopok, továbbképzések, konferenciák, esküvők vagy akár koncertek helyszíne is lehet az Bozsik Aréna.",
            },
            {
              id: 3,
              active: false,
              question:
                "Milyen szolgáltatásokat tudnak biztosítani a rendezvények során?",
              answer:
                "Előzetes egyeztetés alapján nagyon sokféle igényt ki tudunk elégíteni.",
            },
            {
              id: 4,
              active: false,
              question: "Van lehetőség catering szolgáltatásra?",
              answer:
                "Igen, a fingerfoodtól a többfogásos étkezésig mindenre van megoldásunk.",
            },
          ],
        },
      ],
    };
    this.toggleCategory = this.toggleCategory.bind(this);
    this.toggleQuestion = this.toggleQuestion.bind(this);
  }

  toggleCategory(id) {
    console.log(id);
    let faqItems = this.state.faqItems;
    faqItems.forEach((value, index) => {
      if (value.id === id) {
        faqItems[index].active = !faqItems[index].active;
      } else {
        faqItems[index].active = false;
      }
    });

    this.setState({
      faqItems: faqItems,
    });
  }

  toggleQuestion(category, item) {
    console.log(this.state.faqItems);
    let faqItems = this.state.faqItems;
    faqItems.forEach((value, index) => {
      if (value.id === category) {
        value.items.forEach((faqItem, idx) => {
          if (faqItem.id === item) {
            faqItems[index].items[idx].active =
              !faqItems[index].items[idx].active;
          } else {
            faqItems[index].items[idx].active = false;
          }
        });
      }
    });

    this.setState({
      faqItems: faqItems,
    });
  }

  render() {
    return (
      <div>
        <section id="faq" className="faq-container">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Gyakori kérdések</h2>
              </div>
              {this.state.faqItems.map((item, key) => (
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={0.5}
                  animateOnce
                  className="col-12"
                  key={key}
                >
                  <div
                    className={
                      item.active == true
                        ? "faq-category active"
                        : "faq-category"
                    }
                  >
                    <a onClick={this.toggleCategory.bind(null, item.id)}>
                      {item.name}
                    </a>
                    <div className="faq-item-container">
                      {item.items.map((faqItem, key) => (
                        <div
                          key={key}
                          className={
                            faqItem.active == true
                              ? "faq-item active"
                              : "faq-item"
                          }
                        >
                          <a
                            onClick={this.toggleQuestion.bind(
                              null,
                              item.id,
                              faqItem.id
                            )}
                          >
                            {faqItem.question}
                          </a>
                          <p
                            dangerouslySetInnerHTML={{ __html: faqItem.answer }}
                          ></p>
                        </div>
                      ))}
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
