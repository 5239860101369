import {STATIC_SERVER_URL} from "../../../config/config";
import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default class LinksContainer extends Component {
    render() {
        return (
            <div>
                <section className="links-container">
                    <div className="container">
                        <div className="row">
                            <div className="links-container__element col-sm-12 col-lg-6">
                                <ScrollAnimation animateIn="fadeInLeftBig" animateOnce>
                                    <figure>
                                        <a href="https://honvedfc.hu" target="_blank">
                                            <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/cards/card-honvedfc-220820.png`}/>
                                        </a>
                                    </figure>
                                </ScrollAnimation>
                            </div>
                            <div id="tickets" className="col-sm-12 col-lg-6">
                                <ScrollAnimation animateIn="fadeInRightBig" animateOnce>
                                    <figure>
                                        <a href="https://jegy.mlsz.hu/hun/PlaceInfo/3008/Budapest_Honved" target="_blank">
                                            <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/cards/card-jegyek-220820.png`}/>
                                        </a>
                                    </figure>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}