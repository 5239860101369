import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {STATIC_SERVER_URL} from "../../../config/config";
import {Parallax} from "react-parallax";

export default class ArenaVideo extends Component {
    render() {
        return (
            <div>
                {/*<ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <section className="video-container">
                        <div className="container video-content">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/6hVdURzMF3k" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </section>
                </ScrollAnimation>
                <div className="arena-background">
                    <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/header/video-placeholder.png`}/>
                </div>*/}

                <Parallax blur={0} bgImage={`${STATIC_SERVER_URL}/web/bozsik-arena/header/video-background.jpg`} bgImageAlt="" strength={400}>
                </Parallax>
            </div>
        )
    }
}