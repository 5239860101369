import {STATIC_SERVER_URL} from "../../../config/config";
import React, {Component} from "react";

export default class ArenaGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePhoto: 0,
            photos: [
                {
                    active: true,
                    fileName: 'bzsk_k01.jpg'
                },
                {
                    active: false,
                    fileName: 'bzsk_k02.jpg'
                },
                {
                    active: false,
                    fileName: 'bzsk_k03.jpg'
                },
                {
                    active: false,
                    fileName: 'bzsk_k04.jpg'
                },
                {
                    active: false,
                    fileName: 'bzsk_k05.jpg'
                },
                {
                    active: false,
                    fileName: 'bzsk_k06.jpg'
                }
            ]
        };
        this.setActivePhoto = this.setActivePhoto.bind(this);
    }

    setActivePhoto(newValue) {
        console.log(newValue);
        let active = this.state.activePhoto + newValue;
        if (active < 0) active = 0;
        if (active == this.state.photos.length) active = (this.state.photos.length - 1);
        let photos = this.state.photos;

        photos.forEach((value, index) => {
            if (index === active) {
                photos[index].active = true;
            } else {
                photos[index].active = false;
            }
        });

        this.setState({
            photos: photos,
            activePhoto: active
        });
    }

    render() {
        return (
            <div>
                <section className="gallery-container">
                    <div className="container">
                        <div className="gallery-image-container">
                            <div className="gallery-pager gallery-pager__left">
                                <a onClick={this.setActivePhoto.bind(null, -1)} title="előző kép">
                                    <span className="arrow-left"></span>
                                </a>
                            </div>
                            <div className="gallery-pager gallery-pager__right">
                                <a onClick={this.setActivePhoto.bind(null, 1)} title="következő kép">
                                    <span className="arrow-right"></span>
                                </a>
                            </div>
                            <figure>
                                {this.state.photos.map((item, key) => (
                                    <div key={key} className={(item.active == true) ? "product-image__item active" : "product-image__item"}>
                                        <img src={`${STATIC_SERVER_URL}/web/images/stadium/${item.fileName}`}/>
                                    </div>
                                ))}
                            </figure>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}