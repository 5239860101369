import './App.scss';
import Frontpage from "./components/Frontpage/Frontpage";
import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {
  return (
      <div>
          <Router>
              <Switch>
                  <Route exact path="/" component={Frontpage} />
                  <Route exact path="/nyitolap" component={Frontpage} />
                  <Route exact path="/stadiontura" component={Frontpage} />
              </Switch>
          </Router>
      </div>
  );
}

export default App;
