import React, {Component} from "react";
import './navigation.scss';
import {STATIC_SERVER_URL} from "../../../config/config";
import ReactGA from "react-ga";

export default class Navigation extends Component {
    constructor(props) {
        super(props);
        this.scrollToContent = this.scrollToContent.bind(this);
    }

    scrollToContent(menu) {
        let el = document.getElementById(menu);
        el.scrollIntoView({behavior: "smooth"});
        this.sendPageView(menu);
    }

    sendPageView(path) {
        ReactGA.pageview('/' + path);
    }

    render() {
        return (
            <div>
                <nav className="main-navigation">
                    <ul>
                        <li>
                            <a onClick={() => this.scrollToContent('bozsik-arena')}>A Bozsik Aréna</a>
                        </li>
                        <li>
                            <a onClick={() => this.scrollToContent('events')}>Események</a>
                        </li>
                        <li>
                            <a onClick={() => this.scrollToContent('stadium-tour')}>Stadiontúra</a>
                        </li>
                        <li>
                            <a onClick={() => this.sendPageView('sponsorship')} href={`${STATIC_SERVER_URL}/web/bozsik-arena/docs/BOZSIK_KIAJANLO_20211102_online.pdf`} target="_blank">Szponzoráció</a>
                        </li>
                        <li className="spacer"></li>
                        <li>
                            <a onClick={() => this.scrollToContent('tickets')}>Honvéd Jegyek</a>
                        </li>
                        <li>
                            <a onClick={() => this.scrollToContent('faq')}>Gyakori kérdések</a>
                        </li>
                        <li>
                            <a onClick={() => this.scrollToContent('contact')}>Kapcsolat</a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}