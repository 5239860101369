import React, {Component} from "react";
import './header.scss';
import Navigation from "../Navigation/Navigation";
import {STATIC_SERVER_URL} from "../../../config/config";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileNavigationActive: false
        };
        this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
    }

    toggleMobileNavigation() {
        this.setState({
            isMobileNavigationActive: !this.state.isMobileNavigationActive
        });
    }

    render() {
        return (
            <div>
                <header className="header-container not-show-on-mobile">
                    <div className="line-black"></div>
                    <div className="line-red"></div>
                    <div className="container header-container__content">
                        <figure className="logo">
                            <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/header/bozsik-arena-logo.png`} />
                        </figure>
                        <Navigation/>
                    </div>
                </header>

                <header className="mobile-header-container">
                    <figure className="stadium">
                        <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/header/header-bg-mobile.png`} />
                    </figure>
                    <div className="hamburger-container">
                        <div className={(this.state.isMobileNavigationActive) ? 'hamburger-button open' : 'hamburger-button'} onClick={this.toggleMobileNavigation}>
                            <div>
                                <div className="lines"></div>
                                <div className="lines"></div>
                                <div className="lines"></div>
                            </div>
                        </div>
                        <div className={(this.state.isMobileNavigationActive) ? 'show' : 'hidden'}>
                            <Navigation/>
                        </div>
                    </div>

                </header>
            </div>
        )
    }
}