import {API_BASE_URL, STATIC_SERVER_URL} from "../../../config/config";
import React, {Component} from "react";
import moment from "moment";
import ScrollAnimation from "react-animate-on-scroll";

export default class EventsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: []
        };
        this.getEvents = this.getEvents.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    componentDidUpdate() {
    }

    getEvents() {
        fetch(API_BASE_URL + '/fixtures/next-home/5', {
            method: 'GET'
        })
        .then(res => res.json())
        .then(
            (json) => {
                this.setState({
                    events: json
                });
            },
            (error) => {
                console.log(error);
            }
        )
    }

    render() {
        return (
            <div>
                <section id="events" className="events-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Események</h2>
                            </div>
                            <div className="col-12 padding5">
                                {this.state.events.map((item, index) => (
                                <ScrollAnimation animateIn="fadeIn" duration={0.5} delay={index * 100 * 1.2} animateOnce key={item.id}>
                                <div className="event-item__container">
                                    <div className="event-item__content">
                                        <div className="crests">
                                            <img src={`${STATIC_SERVER_URL}/web/images/crests/${item.home_squad_crest}`} alt=""/>
                                            <span>VS</span>
                                            <img src={`${STATIC_SERVER_URL}/web/images/crests/${item.away_squad_crest}`} alt=""/>
                                        </div>
                                        <div className="kickoff">
                                            <p>{moment(item.event_date).format('YYYY.MM.DD HH:mm')}<br/>{item.event_location}</p>
                                        </div>
                                        <div className="type">
                                            <p>{item.event_name}</p>
                                        </div>
                                    </div>
                                </div>
                                </ScrollAnimation>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}