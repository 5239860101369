import React, {Component, useEffect} from "react";
import { useLocation, useParams } from "react-router-dom";

import Header from "../shared/Header/Header";
import ArenaVideo from "./arena-video/ArenaVideo";
import ArenaHistory from "./arena-history/ArenaHistory";
import ArenaGallery from "./arena-gallery/ArenaGallery";
import LinksContainer from "./links-container/LinksContainer";
import EventsContainer from "./events-container/EventsContainer";
import FaqContainer from "./faq/faq-container";
import Contact from "./contact/Contact";
import Footer from "../shared/Footer/Footer";
import StadiumTour from "./stadium-tour/StadiumTour";
import ReactGA from 'react-ga';

import './frontpage.scss';

function Frontpage(props) {
  let { pathname, search, hash } = useLocation();

  const scrollToStadiumTour = () => {
    const elem = document.getElementById('contact');
    window.scrollTo({ top: elem.offsetTop, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (pathname === '/stadiontura') {
      scrollToStadiumTour();
    }

    try {
      ReactGA.initialize('UA-6882995-12');
      ReactGA.pageview(pathname);
    } catch (e) {
        console.log(e);
    }
  }, []);

  return (
    <div>
      <Header/>
      <ArenaVideo/>
      <ArenaHistory/>
      <ArenaGallery/>
      <LinksContainer/>
      <EventsContainer/>
      <StadiumTour />
      <FaqContainer/>
      <Contact pathname={pathname} />
      <Footer/>
    </div>
  );
}

export default Frontpage;
