import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default class StadiumTour extends Component {
  constructor(props) {
    super(props);
    this.scrollToContent = this.scrollToContent.bind(this);
  }

  scrollToContent(menu) {
    let el = document.getElementById(menu);
    el.scrollIntoView({behavior: "smooth"});
  }

  render() {
    return (
      <div>
        <section id="stadium-tour" className="stadium-tour-container">
          <div className="container">
            <ScrollAnimation animateIn="fadeIn" duration={1.5} animateOnce>
              <h2>Stadiontúra</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.5} animateOnce>
              <p>A legendás Lipták Grund környékén már több, mint száztíz éve rúgják a labdát a kispesti focisták. Stadiontúránk keretében megismerhetik kispesti pályák és a Honvéd történelmének kevesek által ismert érdekességeit, a Bozsik Aréna építésével kapcsolatos adatokat és információkat, meghallgathatják az első magyar ultracsoport otthonának, a legendás Északi Kanyarnak a történetét, megnézhetik a stadion mérkőzésnapokon sem látogatható részeit, sőt még a pálya szélére is bejuthatnak! A program ára 1490 Ft / fő, 18 év alatt 1190 Ft / fő. Jelentkezni <a onClick={() => this.scrollToContent('contact')}>ide kattintva</a> tudnak, sok szeretettel várjuk önöket!</p>
            </ScrollAnimation>
          </div>
        </section>
      </div>
    )
  }
}
