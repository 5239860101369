import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default class ArenaHistory extends Component {
    render() {
        return (
            <div>
                <section id="bozsik-arena" className="stadium-history-container">
                    <div className="container">
                        <ScrollAnimation animateIn="fadeIn" duration={1.5} animateOnce>
                            <h2>A Bozsik Aréna</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.5} animateOnce>
                            <p>A Dél-Pesten egyedülálló, modern és színes létesítmény végre megnyitotta kapuit az érdeklődők előtt. A vadonatúj Bozsik Aréna jóval több egy stadionnál. Sportközpont, rendezvényhelyszín, találkozási pont, így nem túlzás azt állítani, hogy a legszélesebb körben szolgálja ki látogatóit. Munkatársaink több évtizedes tapasztalattal rendelkeznek a rendezvényszervezés területén, így biztos lehet benne, hogy prémium szolgáltatásban lesz része. Akár konferenciára, céges karácsonyra, koncertre vagy bármilyen más eseményre keres helyszínt, szakembereink minden elvárását igyekeznek majd felülmúlni, és megtenni a lehető legtöbbet annak érdekében, hogy elégedetten távozzon a Bozsik Arénából. Létesítményünk célja, hogy ne csak a sport szerelmeseinek, de a tárgyalni vagy szórakozni vágyók számára is felhőtlen időtöltést biztosítsunk a vörös-fekete szentélyben. Nem hiába lett a Honvéd család új otthona: az érzelmek Arénája!</p>
                        </ScrollAnimation>
                    </div>
                </section>
            </div>
        )
    }
}