import React, {Component} from "react";
import {AUTH_BASE_URL, STATIC_SERVER_URL} from "../../../config/config";
import ScrollAnimation from "react-animate-on-scroll";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            subject: (this.props.pathname === '/stadiontura') ? 3 : 1,
            tour_date: 0,
            name_error: '',
            email_error: '',
            message_error: '',
            tour_date_error: '',
            is_loader_active: false,
            message_send_error: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        this.setState({
            is_loader_active: true
        });

        event.preventDefault();
        if (this.validate() === true) {
            this.saveRegisterData();
        } else {
            this.setState({
                is_loader_active: false
            });
        }
    }

    validate() {
        let isValid = true;

        if (this.state.message.length < 5) {
            this.setState({message_error: 'Túl rövid az üzenet!'});
            isValid = false;
        } else {
            this.setState({message_error: ''});
        }

        if (this.state.name.length < 3) {
            this.setState({name_error: 'Helytelen név!'});
            isValid = false;
        } else {
            this.setState({name_error: ''});
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.state.email).toLowerCase())) {
            this.setState({email_error: 'Helytelen e-mail cím!'});
            isValid = false;
        } else {
            this.setState({email_error: ''});
        }

        console.log(Number(this.state.subject));
        console.log(this.state.tour_date);
        if (Number(this.state.subject) === 3 && this.state.tour_date === 0) {
          this.setState({tour_date_error: 'Válassz ki egy túra időpontot!'});
          isValid = false;
        } else {
          this.setState({tour_date_error: ''});
        }

        /*if (this.state.accept_terms !== true) {
            this.setState({accept_terms_error: 'A feltételek elfogadása kötelező!'});
            isValid = false;
        } else {
            this.setState({accept_terms_error: ''});
        }*/

        return isValid;
    }

    saveRegisterData() {
        fetch(AUTH_BASE_URL + '/arena-contact/store', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
        })
        .then(res => res.json())
        .then(
            (json) => {
                console.log(json);
                if (json.success == true) {
                    this.setState({
                        message_send_error: false,
                        is_loader_active: false
                    });
                } else {
                    this.setState({
                        message_send_error: true,
                        is_loader_active: false
                    });
                }
            },
            (error) => {
                this.setState({
                    is_loader_active: false
                });
            }
        )
    }

    handleChange(event) {
        const isCheckbox = event.target.type === 'checkbox';

        this.setState({
            [event.target.id]: isCheckbox
                ? event.target.checked
                : event.target.value
        });
    }

    render() {
        return (
            <div>
                <section id="contact" className="contact-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Kapcsolat</h2>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <ScrollAnimation animateIn="fadeInLeftBig" animateOnce>
                                    <figure>
                                        <img src={`${STATIC_SERVER_URL}/web/bozsik-arena/header/bozsik-arena-logo.png`}/>
                                    </figure>
                                </ScrollAnimation>
                            </div>
                            <div className="col-sm-12 col-lg-8">
                                {this.state.message_send_error === true && (<div className="message-block message-block--error">
                                    Az üzenetküldés sikertelen volt, kérjük próbáld meg később!
                                </div>)}
                                {this.state.message_send_error === false && (<div className="message-block message-block--success">
                                    Üzenetedet sikeresen elküldtük, rövidesen válaszolunk.
                                </div>)}
                                <ScrollAnimation animateIn="fadeInRightBig" animateOnce>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-6">
                                                <label htmlFor="last_name">Név <span>*</span></label>
                                                <input type="text" className="form-control" id="name" value={this.state.name}
                                                       onChange={this.handleChange}/>
                                                <div className="validation_error">{this.state.name_error}</div>
                                            </div>

                                            <div className="form-group col-6">
                                                <label htmlFor="email">E-mail cím <span>*</span></label>
                                                <input type="text" className="form-control" id="email" value={this.state.email}
                                                       onChange={this.handleChange}
                                                       onBlur={this.handleEmailChange}/>
                                                <div className="validation_error">{this.state.email_error}</div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="email">Tárgy <span>*</span></label>
                                                <select id="subject" className="form-control" value={this.state.subject}
                                                       onChange={this.handleChange}>
                                                    <option value="1">Rendezvényekkel/eseményekkel kapcsolatban érdeklődöm</option>
                                                    <option value="2">Szponzorációval, együttműködéssel kapcsolatban érdeklődöm</option>
                                                    <option value="3" disabled>Stadiontúrára jelentkezem</option>
                                                    <option value="4">Egyéb</option>
                                                </select>
                                                <div className="validation_error">{this.state.subject_error}</div>
                                            </div>
                                            {this.state.subject == 3 && (<div className="form-group col-12">
                                                <label htmlFor="email">Választott időpont <span>*</span></label>
                                                <select id="tour_date" className="form-control" value={this.state.tour_date}
                                                        onChange={this.handleChange}>
                                                    <option value="0">válassz!</option>
                                                    <option value="2023.08.03. 10:00">2023.08.03. 10:00</option>
                                                    <option value="2023.08.03. 17:00">2023.08.03. 17:00</option>
                                                </select>
                                                <div className="validation_error">{this.state.tour_date_error}</div>
                                            </div>)}
                                            <div className="form-group col-12">
                                                <label htmlFor="first_name">Üzenet <span>*</span></label>
                                                <textarea className="form-control" id="message" value={this.state.message}
                                                       onChange={this.handleChange} />
                                                <div className="validation_error">{this.state.message_error}</div>
                                            </div>
                                            <div className="form-group col-12">
                                                <button type="submit" className="btn btn-primary" disabled={(this.state.is_loader_active === true)}>Küldés</button>
                                            </div>
                                        </div>
                                    </form>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2698.434318349877!2d19.15291995185986!3d47.44247267907189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c2f4f0bad469%3A0xb1252d757d0d112b!2sBozsik%20Ar%C3%A9na!5e0!3m2!1shu!2shu!4v1626001320888!5m2!1shu!2shu"
                        width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
                </section>
            </div>
        )
    }
}
